import React, { useState, useEffect } from 'react';

const PasswordProtection = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const correctPassword = 'helpmyhouse'; // Replace with your desired password

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const enteredPassword = e.target.password.value;
    if (enteredPassword === correctPassword) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
    } else {
      alert('Incorrect password');
    }
  };

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <div className="password-protection">
      <h2>Enter Password</h2>
      <form onSubmit={handleSubmit}>
        <input type="password" name="password" required />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PasswordProtection;