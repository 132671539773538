import React, { useState, useEffect } from 'react';
import TradesList from './components/TradesList';
import TagCloud from './components/TagCloud';
import PasswordProtection from './components/PasswordProtection'; 
import './App.css';

function App() {
  const [trades, setTrades] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/trades.json`)
      .then(response => response.json())
      .then(data => {
        setTrades(data.trades);
      })
      .catch(error => console.error('Error loading data:', error));
  }, []);

  const handleTagClick = (tag) => {
    // If the clicked tag is already selected, deselect it
    // Otherwise, select the new tag
    setSelectedTag(tag === selectedTag ? null : tag);
  };

  const filteredTrades = selectedTag
    ? trades.filter(trade => trade.tags && trade.tags.includes(selectedTag))
    : trades;

    return (
      <PasswordProtection>
      <div className="App">
        <header className="App-header">
          <div className="header-logo-title">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="HouseHeld Logo" className="App-logo" />
            <h1>HouseHeld</h1>
          </div>
          <span className="postcode">HP22</span>
        </header>
        <div className="sub-header">
          <p className="interaction-hint">Filter by job:</p>
        </div>
        <main>
          <TagCloud trades={trades} selectedTag={selectedTag} onTagClick={handleTagClick} />
          <TradesList trades={filteredTrades} showCategories={!selectedTag} />
        </main>
      </div>
      </PasswordProtection>
    );
  }
  
  export default App;